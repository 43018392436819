module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"hu","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"languages":["de","en","hu"],"localeJsonSourceName":"locales","siteUrl":"https://mehesapartman.hu"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#888888","display":"fullscreen","icon":"static/media/images/misc/favicon.png","name":"Méhes Apartman","lang":"hu","localize":[{"lang":"de","name":"Appartement Méhes","start_url":"/de/"},{"lang":"en","name":"Apartment Méhes","start_url":"/en/"}],"short_name":"AM","start_url":"/","theme_color":"#22262a","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80460bb235ac39379e55dce343316e2f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
